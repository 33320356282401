import * as React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const pageTitle = "Back Stick Electric"

export default function HomePage() {
  return (
    <Layout pageTitle={pageTitle}>
      <header className="page-header">
        <h1>{pageTitle}</h1>
      </header>
      <section className="container">
        <StaticImage src="../images/backstick-guys.jpg" alt="Back Stick guys" />
      </section>

      <section className="reading container">
        <p>
          Back Stick Electric is a partnership between two good friends and
          soccer teammates, Thor Hansgen and Ian Rowberry. Together, they offer
          a wide range of electrical and renewable energy services to homeowners
          and businesses in Kingston and the surrounding area.
        </p>

        <p>
          Thor is a licensed Master Electrician with a solid reputation in the
          trade, while Ian is a certified Energy Systems Engineering
          Technologist with a strong background in grid-tied solar power. Both
          have been working in the field for over ten years, and now bring their
          on-field chemistry to your electrical projects.
        </p>

        <h2>Services offered include:</h2>

        <ul>
          <li>
            <strong>Solar power</strong> - we will design custom solar
            installations for your home or business property, with a focus on
            grid-tied power that meets your needs.
          </li>
          <li>
            <strong>Residential renovation</strong> - we will liaise with you or
            your contractor on any electrical services required for home renos
            large and small.
          </li>
          <li>
            <strong>Power to out-buildings</strong> - we will connect your
            backyard bunkie, art studio or garage; Service replacement or
            upgrade - we will carry out the installation of your new or
            larger-capacity electrical panel.
          </li>
          <li>
            <strong>New construction</strong> - we work with homebuilders to
            provide start-to-finish electrical installation of new homes.
          </li>
          <li>
            <strong>Appliance power supply</strong> - we will help you connect
            your new heating equipment, EV charger or hot-tub, making sure
            you’re in the safe hands of a licensed, insured and experienced
            electrical contractor.
          </li>
          <li>
            <strong>Commercial installation</strong> - if you're planning a new
            business space or need to make changes to an existing workplace, we
            can help.
          </li>
          <li>
            <strong>Peace of mind</strong> - we're insured, we're careful, we're
            keen to make it all work for you. If it's something else electrical
            you're looking for, <Link to="contact">you can always ask</Link>!
          </li>
        </ul>
      </section>

      <section className="accent">
        <section className="reading container">
          <h2>Testimonials</h2>
          <figure className="quote">
            <blockquote>
              "Thor and Ian did a wonderful job upgrading our electrical panel.
              They showed up on time, were neat and profesional, and liaised with
              the city staff on our behalf. Happy to recommend their services."
            </blockquote>
            <figcaption>Paul & Kyoko</figcaption>
          </figure>
        </section>
      </section>
    </Layout>
  )
}
